<template>
  <div>
    <div
      class="special--text px-3"
      style="padding-bottom: 6px !important; padding-top: 6px !important"
    >
      <div v-if="!showLeagues" class="d-flex">
        <v-icon color="special">mdi-magnify</v-icon>
        <v-text-field
          v-model="search"
          outlined
          clearable
          placeholder="Search..."
          type="text"
          dense
          hide-details=""
          class="align-center"
        >
        </v-text-field>
      </div>
      <div v-else class="d-flex align-center">
        <v-btn icon @click="showLeagues = false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="pl-3 f-13 font-weight-bold special--text">{{
          selectedCountry.name
        }}</span>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="border-top py-2 d-flex align-center justify-center"
    >
      <div>
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-else class="border-top">
      <div v-if="!showLeagues">
        <div
          v-for="(country, pos) in filteredCountries"
          :key="pos"
          @click="fetchLeaguesByCountry(country)"
          class="d-flex align-center pointer my-3"
        >
          <span class="pl-3 f-13 font-weight-bold special--text">{{
            country.name
          }}</span>
        </div>
      </div>
      <div v-else>
        <div
          v-if="leaguesFetching"
          class="py-2 d-flex align-center justify-center"
        >
          <div>
            <v-progress-circular
              :size="30"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
        <div
          v-for="(league, pos) in leaguesList"
          :key="pos"
          @click="pushToLeague(league)"
          class="d-flex align-center pointer my-3 ml-2"
          v-else
        >
          <img
            :src="league.img"
            width="22"
            height="15"
            alt=""
            class="country-img"
          />
          <p
            class="pl-3 f-13 mb-0 font-weight-bold special--text"
            style="
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            {{ league.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      showLeagues: false,
      selectedCountry: {},
      countriesList: [],
      isLoading: false,
      leaguesList: [],
      leaguesFetching: false,
    };
  },

  computed: {
    filteredCountries() {
      return this.countriesList.filter((country) =>
        country.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(["Get_Countries_List", "Get_Leagues_By_Country"]),

    pushToLeague(league) {
      this.showLeagues = false;
      let country = league.country.name.toLowerCase();
      let leagueName = league.name.replace(/ /g, "-").toLowerCase();
      this.$store.commit("setLeagueDetails", league);
      this.$store.commit("setLeagueID", league.id);
      this.$store.commit("setSeasonID", league.id_current_season);
      this.$router.push("/en/football/" + country + "/" + leagueName);
    },

    async fetchCountries() {
      this.isLoading = true;
      await this.Get_Countries_List()
        .then((data) => {
          this.countriesList = data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },

    async fetchLeaguesByCountry(country) {
      this.showLeagues = true;
      this.leaguesFetching = true;
      this.selectedCountry = country;
      this.leaguesList = [];
      await this.Get_Leagues_By_Country(country.id)
        .then((data) => {
          this.leaguesList = data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.leaguesFetching = false;
    },
  },

  created() {
    this.fetchCountries();
  },
};
</script>

<style scoped lang="sass">
::v-deep .v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot
  border: 0px !important
  font-size: 12px
.border-top
  border-top: 1px solid #222222
.country-img
  border-radius: 2px
.f-13
  font-size: 13px
::v-deep .v-expansion-panel-header__icon
  display: none !important
::v-deep .v-expansion-panel-header
  padding: 10px 10px
  min-height: 36px !important
::v-deep .v-expansion-panel-content__wrap
  padding: 10px 10px
  border-top: 1px solid #C97434
</style>
