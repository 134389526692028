<template>
  <div class="px-3">
    <div class="my-2">
      <v-chip 
        v-for="(nav, NI) in navs" 
        :key="NI" 
        @click="clickedTab(nav.id)" 
        class="mx-2 my-2 pointer font-weight-black f-12"
        :class="activeNavId === nav.id ? 'white background--text':'secondary special--text'"
      >
        {{nav.name}}
      </v-chip>
    </div>
    <div v-if="activeNavId == 1">
      <Events :halfTimeScore='halfTimeScore' :fullTimeScore='fullTimeScore' />
    </div>
    <div v-if="activeNavId == 2">
      <Commentary />
    </div>
  </div>
</template>

<script>
import Events from '@/components/match/Events'
import Commentary from '@/components/match/Commentary'
export default {
  components: {
    Events,
    Commentary
  },
  props: ['halfTimeScore', 'fullTimeScore'],
  data() {
    return {
      navs: [
        {id: 1, name: 'EVENTS'},
        {id: 2, name: 'COMMENTARY'},
      ],
      activeNavId: 1
    }
  },
  methods: {
    clickedTab(id) {
      this.activeNavId = id
    }
  }
}
</script>

<style scoped lang='sass'>
.pointer
  cursor: pointer
.f-12
  font-size: 12px
</style>