<template>
<div>
<v-header class="v-toolbar v-toolbar--flat v-toolbar--density-default bg-grey-lighten-4 border-b v-theme--light ps-1">
    <v-app-bar color="background" dark>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="special"
      ></v-app-bar-nav-icon>

      <div
        class="justify-space-between ml-4 naviagtion-bar"
        style="width: 100%"
      >
        <v-btn text class="white--text" to="/">
          <h2><span class="primary--text">LiveScore</span>-com-1</h2>
        </v-btn>
        <div>
          <v-btn
            text
            :class="!getIsFavourite ? 'primary--text' : 'special--text'"
            to="/en/football"
            @click="scoreClicked"
          >
            <v-icon>mdi-football</v-icon>
            <span class="pl-2 font-weight-black">Scores</span>
          </v-btn>
        </div>
        <div>
          <v-btn
            text
            :class="getIsFavourite ? 'primary--text' : 'special--text'"
            to="/en/football/favourites"
            @click="favouritesClicked"
          >
            <v-icon>mdi-star</v-icon>
            <span class="pl-2 font-weight-black">Favourites</span>
          </v-btn>
        </div>
        <div>
          <v-btn text class="special--text">
            <v-icon>mdi-football</v-icon>
            <span class="pl-2 font-weight-black"> News</span>
          </v-btn>
        </div>
        <div>
          <v-btn text class="white--text">
            <v-icon>mdi-football</v-icon>
            <span class="pl-2 font-weight-black"> Get the app</span>
          </v-btn>
        </div>
      </div>
      <div
        class="responsive-nav-bar justify-space-between align-center"
        style="width: 100%"
      >
        <div class="ml-4">
          <h2><span class="primary--text">LiveScore</span>-com</h2>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
  </v-header>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      color="#181818"
    >
      <v-list nav dense class="mt-16">
        <v-list-item-group v-model="group">
          <div class="d-flex align-center ml-3 mb-3" style="width: 100%">
            <h3><span class="primary--text">LiveScore</span>-com</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="drawer = false" class="mr-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <v-list-item link to="/en/settings">
            <v-list-item-icon class="mr-2">
              <v-icon size="16">mdi-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-icon class="mr-2">
              <v-icon size="16">mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-icon class="mr-2">
              <v-icon size="16">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Advertise</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-icon class="mr-2">
              <v-icon size="16">mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mobile</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    drawer: false,
    group: null,
    isFavourite: false,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    ...mapGetters(["getIsFavourite"]),
  },
  methods: {
    ...mapMutations(["setIsFavourite"]),
    favouritesClicked() {
      this.isFavourite = true;
      this.setIsFavourite(this.isFavourite);
    },
    scoreClicked() {
      this.isFavourite = false;
      this.setIsFavourite(this.isFavourite);
    },
  },
};
</script>

<style scoped lang="sass">
.naviagtion-bar
  display: flex
  @media screen and (max-width: 961px)
    display: none !important
.responsive-nav-bar
  display: none !important
  @media screen and (max-width: 961px)
    display: flex !important
</style>
