<template>
  <div v-if="homeValue || awayValue">
    <div class="d-flex justify-space-between px-1">
      <p class="mb-0 f-13 font-weight-bold special--text">{{homeValue}}</p>
      <p class="mb-0 f-13 font-weight-bold special--text">{{title}}</p>
      <p class="mb-0 f-13 font-weight-bold special--text">{{awayValue}}</p>
    </div>
    <div class="d-flex">
      <v-col cols="6" class="pa-1">
        <v-progress-linear
          v-model="calHomeValue"
          height="10"
          color="primary"
          value="50"
          reverse
        ></v-progress-linear>
      </v-col>
      <v-col cols="6" class="pa-1">
        <v-progress-linear
          v-model="calAwayValue"
          height="10"
          color="primary"
        ></v-progress-linear>
      </v-col>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: ['homeValue', 'awayValue', 'title'],
  computed: {
    calHomeValue() {
      if(this.homeValue <= 10) return this.homeValue*10
      else if(this.homeValue > 10 && this.homeValue <= 20 ) return this.homeValue*5
      else return this.homeValue
    },
    calAwayValue() {
      if(this.awayValue <= 10) return this.awayValue*10
      else if(this.awayValue > 10 && this.awayValue <= 20 ) return this.awayValue*5
      else return this.awayValue
    }
  }
}
</script>

<style scoped lang='sass'>
.f-13
  font-size: 13px
</style>