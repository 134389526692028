<template>
  <div>
    <div class="d-flex" v-if="!activeNavId">
      <p class="f-13 mb-0 special--text font-weight-bold">FIXTURES</p>
      <v-spacer></v-spacer>
      <v-btn x-small icon>
        <v-icon size="16">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <div class="mt-3">
      <div
        v-if="isLoading"
        class="mx-3 mt-5 loading d-flex align-center justify-center"
      >
        <div>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
      <div
        v-for="(schedule, sI) in getSchedulesOf"
        :key="sI"
        class="secondary px-2 py-2 match-single d-flex align-center mb-2 pointer"
        v-else
      >
        <div style="width: 40px">
          <p
            v-if="
              schedule.status == 1 ||
              schedule.status == 12 ||
              schedule.status == 13 ||
              schedule.status == 14 ||
              schedule.status == 15 ||
              schedule.status == 16
            "
            class="mb-0 f-11 pr-1 text-center primary--text"
          >
            {{ schedule.time.minute }}'
          </p>
          <p
            v-else-if="schedule.status == 11"
            class="mb-0 f-11 pr-1 text-center primary--text"
          >
            HT
          </p>
          <p
            v-else-if="schedule.status == 4"
            class="mb-0 f-11 pr-1 text-center"
          >
            PSP
          </p>
          <p
            v-else-if="schedule.status == 3"
            class="mb-0 f-11 pr-1 text-center"
          >
            FIN
          </p>
          <p
            v-else-if="schedule.status == 31"
            class="mb-0 f-11 pr-1 text-center"
          >
            FIN P.
          </p>
          <p
            v-else-if="schedule.status == 5"
            class="mb-0 f-11 pr-1 text-center"
          >
            CAN
          </p>
          <p v-else class="mb-0 f-11 pr-1 text-center">
            <span>{{ getDateMonth(schedule.time.date) }}</span>
            {{
              schedule.time.time.split(":")[0] +
              ":" +
              schedule.time.time.split(":")[1]
            }}
          </p>
        </div>
        <div>
          <div class="d-flex align-center mb-2">
            <img
              :src="schedule.teams.home.img"
              height="20px"
              width="22px"
              class="flag-image"
            />
            <span class="pl-3 f-14 font-weight-bold">{{
              schedule.teams.home.name
            }}</span>
          </div>
          <div class="d-flex align-center mt-2">
            <img
              :src="schedule.teams.away.img"
              height="20px"
              width="22px"
              class="flag-image"
            />
            <span class="pl-3 f-14 font-weight-bold">{{
              schedule.teams.away.name
            }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div>
          <p class="mb-0">{{ schedule.scores.home_score }}</p>
          <p class="mb-0">{{ schedule.scores.away_score }}</p>
        </div>
        <v-btn icon class="ml-3">
          <v-icon class="star-icon">mdi-star-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["activeNavId", "fixtures", "isLoading"],
  computed: {
    getSchedulesOf() {
      return this.fixtures.filter((s) => s.status == 0);
    },
  },
  methods: {
    getDateMonth(value) {
      let date = new Date(value);
      return (
        date.toString().split(" ")[2].toUpperCase() +
        " " +
        date.toString().split(" ")[1]
      );
    },
  },
};
</script>

<style scoped lang="sass">
.f-13
  font-size: 13px
.my-table
  border: 1px solid #222222
  border-radius: 7px
.match-single
  border-radius: 7px
  color: #AAAAAA
  &:hover
    background-color: #333333 !important
    span
      color: #FDFDFD
.f-11
  font-size: 11px
</style>
