<template>
  <div class="mb-4" style="position: relative">
    <div class="d-flex justify-space-between green-bg">
      <p v-if="selectedMatch.teams.home.name" class="font-weight-bold f-12 pl-5 mb-0 pt-1">{{selectedMatch.teams.home.name}}</p>
      <p class="font-weight-bold f-12 pr-5 mb-0 pt-1">{{allLineups.home.formation}}</p>
    </div>
    <div class="field-formation">
      <img src="../../assets/Football_field.png" alt="">
      <div style="position:absolute; top: 0; width: 100%">
        <div class="goal-keeper d-flex justify-center mt-10">
          <div 
            v-for="(player, PI) in filterPlayer('home', 'goal-keeper')" 
            :key="PI" class=""
          >
            <div class="player-block-home d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="diffender d-flex justify-space-around mt-8">
          <div 
            class=""
            v-for="(player, PI) in filterPlayer('home', 'diffender')" 
            :key="PI" 
          >
            <div class="player-block-home d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="mid-fielder d-flex justify-space-around mt-7">
          <div 
            v-for="(player, PI) in filterPlayer('home', 'mid-fielder')" 
            :key="PI" 
            class=""
          >
            <div class="player-block-home d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="forwarder d-flex justify-space-around mt-6">
          <div
            v-for="(player, PI) in filterPlayer('home', 'forwarder')" 
            :key="PI"
            class=""
          >
            <div class="player-block-home d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div v-if="filterPlayer('home', 'sticker')" class="sticker d-flex justify-space-around">
          <div 
            v-for="(player, PI) in filterPlayer('home', 'sticker')" 
            :key="PI"
            class=""
          >
            <div class="player-block-home d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
      </div>
      <div style="position:absolute; bottom: 26px; width: 100%">
        <div v-if="filterPlayer('away', 'sticker')" class="sticker d-flex justify-center ">
          <div v-for="(player, PI) in filterPlayer('away', 'sticker')" 
            :key="PI" class="">
            <div class="player-block-away d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="forwarder d-flex justify-space-around ">
          <div v-for="(player, PI) in filterPlayer('away', 'forwarder')" 
            :key="PI" class="">
            <div class="player-block-away d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="mid-fielder d-flex justify-space-around mt-8">
          <div v-for="(player, PI) in filterPlayer('away', 'mid-fielder')" 
            :key="PI" class="">
            <div class="player-block-away d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="diffender d-flex justify-space-around mt-8">
          <div v-for="(player, PI) in filterPlayer('away', 'diffender')" 
            :key="PI" class="">
            <div class="player-block-away d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
        <div class="goal-keeper d-flex justify-space-around mt-7">
          <div v-for="(player, PI) in filterPlayer('away', 'goal-keeper')" 
            :key="PI" class="">
            <div class="player-block-away d-flex justify-center align-center mx-auto">
              <p class="mb-0 font-weight-bold">{{player.number}}</p>
            </div>
            <p class="mb-0 font-weight-bold">{{nameFormation(player.player.common_name)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between green-bg">
      <p v-if="selectedMatch.teams.away.name" class="font-weight-bold f-12 pl-5 mb-0 pb-1">{{selectedMatch.teams.away.name}}</p>
      <p class="font-weight-bold f-12 pr-5 mb-0 pb-1">{{allLineups.away.formation}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['allLineups', 'homeTeamLineup', 'awayTeamLineup', 'selectedMatch'],
  data() {
    return {
      homeFormation: [],
      awayFormation: [],
    }
  },
  methods: {
    async dataSet() {
      this.homeFormation = await this.allLineups.home.formation.split('-')
      this.awayFormation = await this.allLineups.away.formation.split('-')
    },
    filterPlayer(team, position) {
      if(team == 'home'){
        switch (position) {
          case 'goal-keeper':
            return this.homeTeamLineup.filter(p=> p.position == 'G')
            break;
          case 'diffender':
            let preDif = 1
            let dif = this.homeFormation[0]
            let difPlayers = []
            for(let i=preDif; i<(preDif + parseInt(dif)); i++) {
              difPlayers.push(this.homeTeamLineup[i])
            }
            return difPlayers
            break;
          case 'mid-fielder':
            let mid = this.homeFormation[1]
            let preMid = 1 + parseInt(this.homeFormation[0])
            let midPlayers = []
            for(let i=preMid; i<(preMid + parseInt(mid)); i++) {
              midPlayers.push(this.homeTeamLineup[i])
            }
            return midPlayers
            break;
          case 'forwarder':
            let forW = this.homeFormation[2]
            let preForW = 1 + parseInt(this.homeFormation[0]) + parseInt(this.homeFormation[1])
            let forWPlayers = []
            for(let i=preForW; i<(preForW + parseInt(forW)); i++) {
              forWPlayers.push(this.homeTeamLineup[i])
            }
            return forWPlayers
            break;
          case 'sticker':
            let preSti = 1 + parseInt(this.homeFormation[0]) + parseInt(this.homeFormation[1]) + parseInt(this.homeFormation[2])
            if(preSti == 11) return false
            let sti = this.homeFormation[3]
            let stiPlayers = []
            for(let i=preSti; i<(preSti + parseInt(sti)); i++) {
              stiPlayers.push(this.homeTeamLineup[i])
            }
            return stiPlayers
            break;
          default:
        }

      }else{
        switch (position) {
          case 'goal-keeper':
            return this.awayTeamLineup.filter(p=> p.position == 'G')
            break;
          case 'diffender':
            let preDif = 1
            let dif = this.awayFormation[0]
            let difPlayers = []
            for(let i=preDif; i<(preDif + parseInt(dif)); i++) {
              difPlayers.push(this.awayTeamLineup[i])
            }
            return difPlayers
            break;
          case 'mid-fielder':
            let mid = this.awayFormation[1]
            let preMid = 1 + parseInt(this.awayFormation[0])
            let midPlayers = []
            for(let i=preMid; i<(preMid + parseInt(mid)); i++) {
              midPlayers.push(this.awayTeamLineup[i])
            }
            return midPlayers
            break;
          case 'forwarder':
            let forW = this.awayFormation[2]
            let preForW = 1 + parseInt(this.awayFormation[0]) + parseInt(this.awayFormation[1])
            let forWPlayers = []
            for(let i=preForW; i<(preForW + parseInt(forW)); i++) {
              forWPlayers.push(this.awayTeamLineup[i])
            }
            return forWPlayers
            break;
          case 'sticker':
            let preSti = 1 + parseInt(this.awayFormation[0]) + parseInt(this.awayFormation[1]) + parseInt(this.awayFormation[2])
            if(preSti == 11) return false
            let sti = this.awayFormation[3]
            let stiPlayers = []
            for(let i=preSti; i<(preSti + parseInt(sti)); i++) {
              stiPlayers.push(this.awayTeamLineup[i])
            }
            return stiPlayers
            break;
          default:
        }
      }
    },
    nameFormation(v) {
      if(v){
        let displayName
        let arrayOfName = v.split(', ')
        let firstName
        if(arrayOfName[1]) {
          let splitName = arrayOfName[1].split('')
          firstName = splitName[0]
        }
        if(firstName) {
          displayName = firstName + '. ' + arrayOfName[0]
        } else {
          displayName = arrayOfName[0]
        }
        return displayName
      }
    },
  },
  created() {
    this.dataSet()
  }
}
</script>

<style scoped lang='sass'>
.field-formation
  height: 750px
  position: relative
  img
    width: 100%
    height: 750px
.f-12
  font-size: 12px
.green-bg
  background: #4FA228
.player-block-home
  height: 30px
  width: 30px
  border-radius: 50%
  background: #0B0B0B 
.player-block-away
  height: 30px
  width: 30px
  border-radius: 50%
  background: #FFFF 
  color: black
</style>