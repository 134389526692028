<template>
  <div>
    <div v-if="isLoading" class="loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-else class="px-3 py-2">
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.shots_on_target"
          :awayValue="awayTeamStats.shots_on_target"
          title="Shots on target"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.shots_off_target"
          :awayValue="awayTeamStats.shots_off_target"
          title="Shots off target"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.shots_blocked"
          :awayValue="awayTeamStats.shots_blocked"
          title="Blocked Shots"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.possessionpercent"
          :awayValue="awayTeamStats.possessionpercent"
          title="Possession (%)"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.corners"
          :awayValue="awayTeamStats.corners"
          title="Corner Kicks"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.offsides"
          :awayValue="awayTeamStats.offsides"
          title="Offsides"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.fouls"
          :awayValue="awayTeamStats.fouls"
          title="Fouls"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.throw_in"
          :awayValue="awayTeamStats.throw_in"
          title="Throw ins"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.yellowcards"
          :awayValue="awayTeamStats.yellowcards"
          title="Yellow cards"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.yellowredcards"
          :awayValue="awayTeamStats.yellowredcards"
          title="Yellow Red cards"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.redcards"
          :awayValue="awayTeamStats.redcards"
          title="Red cards"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.substitutions"
          :awayValue="awayTeamStats.substitutions"
          title="Substitutions"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.goal_attempts"
          :awayValue="awayTeamStats.goal_attempts"
          title="Goal Attempts"
        />
      </div>
      <div class="mb-3">
        <Progress
          :homeValue="homeTeamStats.goal_kick"
          :awayValue="awayTeamStats.goal_kick"
          title="Goal Kicks"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Progress from "@/components/match/StatProgress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      allStats: [],
      isLoading: false,
      statTimeout: false,
      timeOut: null,
    };
  },
  methods: {
    ...mapActions(["Get_Stats_Tab_Content"]),
    calcProgressBar(v) {
      if (v < 10) {
        return (100 - (100 - v)) * 10 + "%";
      } else {
        return 100 - (100 - v) + "%";
      }
    },
    intervalFetchingSchedule() {
      this.timeOut = setInterval(() => {
        this.getStatsData(false);
      }, 5000);
    },
    async getStatsData(loading = true) {
      this.isLoading = loading;
      await this.Get_Stats_Tab_Content(this.$store.state.matchID)
        .then((data) => {
          this.allStats = data;
          console.log(this.allStats, "this.allStats");
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeOut);
  },
  computed: {
    homeTeamStats() {
      return this.allStats.filter(
        (x) => x.team_id == this.$store.state.homeTeamID
      )[0];
    },
    awayTeamStats() {
      return this.allStats.filter(
        (x) => x.team_id == this.$store.state.awayTeamID
      )[0];
    },
  },
  created() {
    this.getStatsData();
    this.intervalFetchingSchedule();
  },
};
</script>

<style scoped lang="sass">
.f-13
  font-size: 13px
.loading
  min-height: 300px
  width: 100%
</style>
