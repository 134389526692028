import axios from '@/axios'

export default{
    state: {
    },
    getters: {
    
    },
    mutations: {
    },
    actions: {
      Get_Countries_List({},id){
        return axios.reqApi('countries/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=list&nopag=23')
        .get().then(data =>{
          return data.data
        }).catch(error =>{
            console.log(error);
        })
      },

      Get_Leagues_By_Country({},id){
        return axios.reqApi('leagues/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=list&country_id='+ id)
        .get().then(data =>{
          return data.data.data
        }).catch(error =>{
            console.log(error);
        })
      },
    }
}