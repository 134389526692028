import axios from "axios";
import store from "../store"
import router from "../router"

var baseURL = "https://api.soccersapi.com/v2.2/";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  }
});

// axiosInstance.interceptors.request.use(
//   config => {
//     let loginStatus = store.getters['getToken']; 
//     if (loginStatus) {
//       config.headers["auth-token"] = store.getters['getToken']
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );


export default {
  reqApi(url) {
    return {
      post: data => axiosInstance.post(baseURL + url, data),
      getByBody: data => axiosInstance.get(baseURL + url, data),
      put: data => axiosInstance.put(baseURL + url, data),
      update: data => axiosInstance.put(baseURL + url, data),
      get: () => axiosInstance.get(baseURL + url),
      getById: id => axiosInstance.get(`${baseURL + url}/${id}`),
      delete: id => axiosInstance.delete(`${baseURL + url}/${id}`),
      putByid: id => axiosInstance.put(`${baseURL + url}/${id}`),
      email: toMail => axiosInstance.post(baseURL + url, toMail)
    };
  },

  BASEURL: baseURL
};
