<template>
  <div class="py-2">
    <div class="d-flex align-center px-3">
      <img
        :src="$store.state.leagueDetails.img"
        height="18px"
        width="25px"
        class="flag-image ml-1"
      />
      <div class="pl-4 pointer">
        <p class="mb-0 font-weight-bold null-line-height">
          {{ $store.state.leagueDetails.name }}
        </p>
        <p class="mb-0 f-11">
          {{ $store.state.leagueDetails.country.name }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-star-outline</v-icon>
      </v-btn>
    </div>
    <v-card class="TabCard my-4" style="box-shadow: none">
      <v-tabs v-model="tab" background-color="#0B0B0B" dark class="px-3">
        <v-tab
          v-for="item in items"
          :key="item.tab"
          class="TabButton font-weight-bold"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="TabButtonActive">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat class="TabButtonActive">
            <div v-if="item.tab == 'Overview'" class="px-3 pt-2">
              <Overview :fixtures="fixtures" :isLoading="isLoading" />
            </div>
            <div v-if="item.tab == 'Matches'">
              <Matches :fixtures="fixtures" />
            </div>
            <div v-if="item.tab == 'Table'">
              <Table />
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/league/Table";
import Overview from "@/components/league/Overview";
import Matches from "@/components/league/Matches";
export default {
  components: {
    Table,
    Overview,
    Matches,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: "Overview", id: 1 },
        { tab: "Matches", id: 2 },
        { tab: "Table", id: 3 },
      ],
      isLoading: false,
      fixtures: [],
    };
  },
  methods: {
    ...mapActions(["Get_Fixtures"]),
    async fetchData() {
      this.isLoading = true;
      await this.Get_Fixtures(this.$store.state.seasonID)
        .then((data) => {
          this.fixtures = data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="sass">
.TabCard
  background-color: inherit !important
.TabButton
  height: 30px
  font-size: 12px
  &:hover
    background-color: inherit !important
.TabButtonActive
  background-color: inherit !important
  border-top: 1px solid #222222
::v-deep .v-tabs-slider-wrapper
  display: none !important
::v-deep .v-tabs
  height: 30px
  margin-bottom: 5px
.flag-image
  border-radius: 3px
.null-line-height
  line-height: auto
.f-11
  font-size: 11px
</style>
