<template>
  <div class="px-3">
    <div class="my-2">
      <v-chip 
        v-for="(nav, NI) in navs" 
        :key="NI" 
        @click="clickedTab(nav.id)" 
        class="mx-2 my-2 pointer font-weight-black f-12"
        :class="activeNavId === nav.id ? 'white background--text':'secondary special--text'"
      >
        {{nav.name}}
      </v-chip>
    </div>
    <div>
      <TableTitle />
    </div>
    <div v-if="activeNavId == 1">
      <AllTeamTable />
    </div>
    <div v-if="activeNavId == 2">
      <HomeTeamTable />
    </div>
    <div v-if="activeNavId == 3">
      <AwayTeamTable />
    </div>
  </div>
</template>

<script>
import AllTeamTable from '@/components/league/AllTeamTable'
import HomeTeamTable from '@/components/league/HomeTeamTable'
import AwayTeamTable from '@/components/league/AwayTeamTable'
import TableTitle from '@/components/common/TableTitle'

export default {
  components: {
    AllTeamTable,
    HomeTeamTable,
    AwayTeamTable,
    TableTitle
  },
  data() {
    return {
      navs: [
        {id: 1, name: 'ALL'},
        {id: 2, name: 'HOME'},
        {id: 3, name: 'AWAY'},
      ],
      activeNavId: 1
    }
  },
  methods: {
    clickedTab(id) {
      this.activeNavId = id
    }
  }
}
</script>

<style scoped lang='sass'>
.pointer
  cursor: pointer
.f-12
  font-size: 12px
</style>