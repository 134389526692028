import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue'
import AppLayoutWidgets from '@/layout/AppLayoutWidgets.vue'
import Game from '@/views/Game.vue'
import League from '@/views/League.vue'
import Match from '@/views/Match.vue'
import Settings from '@/views/Settings.vue'

import Head2Head from '@/widgets/Head2Head.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AppLayout',
    component: AppLayout,
    children: [
      {
        path: '/',
        name: 'Game',
        redirect: '/en/football',
        component: Game,
      },
      {
        path: '/en/football',
        name: 'Game',
        component: Game,
        children: [
          {
            path: ':country/:league', // Removed leading slash
            name: 'League',
            component: League,
          },
          {
            path: ':country/:league/:teams/:id', // Removed leading slash
            name: 'Match',
            component: Match,
          },
        ]
      },
      {
        path: 'football/favourites', // Removed leading slash
        name: 'Game',
        component: Game,
      },
      {
        path: 'settings', // Removed leading slash
        name: 'Settings',
        component: Settings,
      } 
    ]
  },
  {
    path: '/widgets',
    name: 'AppLayoutWidgets',
    component: AppLayoutWidgets,
    children: [  
      {
        path: 'football/h2h/:id',
        name: 'Head2Head',
        component: Head2Head,
      },
      // Add more widget routes here
    ]
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
