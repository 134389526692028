<template>
  <div class="f-13 mx-3 mt-3">
    <div v-if="isLoading" class="loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div class="" v-else>
      <div>
        <TeamFormation
          :allLineups="allLineups"
          :homeTeamLineup="homeTeamLineup"
          :awayTeamLineup="awayTeamLineup"
          :selectedMatch="selectedMatch"
        />
      </div>
      <div>
        <span class="special--text font-weight-bold">LINEUPS</span>
        <div class="my-table d-flex mt-2">
          <v-col cols="6 text-center">
            <div class="d-flex justify-center mb-2">
              <img
                v-if="selectedMatch.teams.home.img"
                :src="selectedMatch.teams.home.img"
                height="20px"
                width="18px"
                class="flag-image mr-3"
              />
              <p
                v-if="selectedMatch.teams.home.name"
                class="mb-0 font-weight-bold f-12"
              >
                {{ selectedMatch.teams.home.name }}
              </p>
            </div>
            <div
              v-for="(home, HI) in homeTeamLineup"
              :key="HI"
              class="d-flex mb-1"
            >
              <p class="mb-0 mr-2" style="width: 30%">{{ home.number }}</p>
              <p class="mb-0">{{ nameFormation(home.player.common_name) }}</p>
            </div>
          </v-col>
          <v-col cols="6 text-center">
            <div class="d-flex justify-center mb-2">
              <img
                v-if="selectedMatch.teams.away.img"
                :src="selectedMatch.teams.away.img"
                height="20px"
                width="18px"
                class="flag-image mr-3"
              />
              <p
                v-if="selectedMatch.teams.away.name"
                class="mb-0 font-weight-bold f-12"
              >
                {{ selectedMatch.teams.away.name }}
              </p>
            </div>
            <div
              v-for="(home, HI) in awayTeamLineup"
              :key="HI"
              class="d-flex mb-1"
            >
              <p class="mb-0 mr-2" style="width: 30%">{{ home.number }}</p>
              <p class="mb-0">{{ nameFormation(home.player.common_name) }}</p>
            </div>
          </v-col>
        </div>
      </div>
      <div class="mt-3">
        <span class="special--text font-weight-bold">BENCH</span>
        <div class="my-table d-flex mt-2">
          <v-col cols="6 text-center">
            <div
              v-for="(home, HI) in homeTeamBench"
              :key="HI"
              class="d-flex mb-1"
            >
              <p class="mb-0 mr-2" style="width: 30%">{{ home.number }}</p>
              <p class="mb-0">{{ nameFormation(home.player.common_name) }}</p>
            </div>
          </v-col>
          <v-col cols="6 text-center">
            <div
              v-for="(home, HI) in awayTeamBench"
              :key="HI"
              class="d-flex mb-1"
            >
              <p class="mb-0 mr-2" style="width: 30%">{{ home.number }}</p>
              <p class="mb-0">{{ nameFormation(home.player.common_name) }}</p>
            </div>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TeamFormation from "@/components/match/TeamFormation.vue";
export default {
  components: {
    TeamFormation,
  },
  data() {
    return {
      isLoading: false,
      allLineups: [],
      homeTeamLineup: [],
      awayTeamLineup: [],
      allLineupsBench: [],
      homeTeamBench: [],
      awayTeamBench: [],
      isLoading: false,
    };
  },
  props: ["selectedMatch"],
  methods: {
    ...mapActions(["Get_Lineups_Tab_Content", "Get_Lineups_Bench_Tab_Content"]),
    nameFormation(v) {
      if (v) {
        let displayName;
        let arrayOfName = v.split(", ");
        let firstName;
        if (arrayOfName[1]) {
          let splitName = arrayOfName[1].split("");
          firstName = splitName[0];
        }
        if (firstName) {
          displayName = firstName + ". " + arrayOfName[0];
        } else {
          displayName = arrayOfName[0];
        }
        return displayName;
      }
    },
    async fetchData(loading = true) {
      this.isLoading = loading;
      await this.lineupContent();
      await this.benchLineupContent();
      this.isLoading = false;
    },
    async lineupContent() {
      await this.Get_Lineups_Tab_Content(this.$store.state.matchID)
        .then((data) => {
          this.allLineups = data;
          this.homeTeamLineup = data.home.squad;
          this.awayTeamLineup = data.away.squad;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async benchLineupContent() {
      await this.Get_Lineups_Bench_Tab_Content(this.$store.state.matchID)
        .then((data) => {
          this.allLineupsBench = data;
          this.homeTeamBench = data.home;
          this.awayTeamBench = data.away;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="sass">
.loading
  min-height: 300px
  width: 100%
.my-table
  border: 1px solid #222222
  border-radius: 7px
.border-t
  border-top: 1px solid #222222
.f-13
  font-size: 13px
.flag-image
  border-radius: 3px
</style>
