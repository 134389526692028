<template>
  <v-footer class="appFooter mt-8">
    <v-container>
      <div class="d-flex flex-wrap align-center justify-center font-weight-bold body-2">
        <router-link v-for="(item, I) in menu" :key="I" :to="item.link" class="text-decoration-none mx-2 mb-1">{{item.name}}</router-link>
      </div>
      <div class="d-flex align-center justify-center mt-4">
        <v-btn icon color="lighten-2" class="mx-1"><v-icon>mdi-facebook</v-icon></v-btn>
        <v-btn icon color="lighten-2" class="mx-1"><v-icon>mdi-twitter</v-icon></v-btn>
        <v-btn icon color="lighten-2" class="mx-1"><v-icon>mdi-linkedin</v-icon></v-btn>
        <v-btn icon color="lighten-2" class="mx-1"><v-icon>mdi-google-plus</v-icon></v-btn>
      </div>
      <div class="d-flex flex-column flex-sm-row align-center justify-center mt-4 overline">
        <div class="">© Livescore-com 2021</div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {id: 1, name: 'Add Coin', link:'#'},
        {id: 2, name: 'Promote', link:'#'},
        {id: 3, name: 'DYOR', link:'#'},
        {id: 4, name: 'Terms & Conditions', link:'#'},
        {id: 5, name: 'Privacy Policy', link:'#'},
        {id: 6, name: 'Contact Us', link:'#'},
      ]
    }
  }
}
</script>
<style scoped lang="sass">
.appFooter
  background-color: #0B0B0B !important
</style>