<template>
  <div class="py-2">
    <v-chip class="mx-2 my-2 pointer white font-weight-black background--text">Football</v-chip>
    <v-chip class="mx-2 my-2 secondary special--text font-weight-black pointer">Hockey</v-chip>
    <v-chip class="mx-2 my-2 secondary special--text font-weight-black pointer">Basketball</v-chip>
    <v-chip class="mx-2 my-2 secondary special--text font-weight-black pointer">Tennis</v-chip>
    <v-chip class="mx-2 my-2 secondary special--text font-weight-black pointer">Cricket</v-chip>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.pointer{
  cursor: pointer;
}
</style>