<template>
  <div class="d-flex justify-space-between table-title px-2 py-2">
    <div class="f-13" style="min-width: 7%">
      #
    </div>
    <div class="f-13" style="min-width: 37%">
      TEAM
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      P
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      W
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      D
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      L
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      F
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      A
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      GD
    </div>
    <div class="f-13 text-center" style="min-width: 7%">
      PTS
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang='sass'>
.f-13
  font-size: 13px
.table-title
  border: 1px solid #222222
  border-top-left-radius: 10px
  border-top-right-radius: 10px
</style>