<template>
  <div>
    <!-- Table Data -->
    <div 
      v-for="(standing, SI) in standingData" 
      :key="SI" 
      class="d-flex justify-space-between px-2 py-2 table-row"
      :class="(((standing.team_id == $store.state.homeTeamID) || (standing.team_id == $store.state.awayTeamID)) && ($route.name == 'Match'))  ? 'primary--text':''"
      >
      <div class="f-13" style="min-width: 7%">
        {{standing.overall.position}}.
      </div>
      <div class="f-13" style="min-width: 37%">
        {{standing.team_name}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.games_played}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.won}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.draw}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.lost}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.goals_scored}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.goals_against}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.goals_diff}}
      </div>
      <div class="f-13 text-center" style="min-width: 7%">
        {{standing.overall.points}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    standingData: Array,
  },
}
</script>

<style scoped lang='sass'>
.f-13
  font-size: 13px
.table-row
  border: 1px solid #222222
  border-top: 0px !important
</style>