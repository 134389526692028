import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Schedule from "./modules/Schedule.js";
import Standing from "./modules/Standing.js";
import Teams from "./modules/Teams.js";
import Draw from "./modules/Draw.js";
import Search from "./modules/Search.js";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    allLeagueSchedule: [],

    matches: {},
    isFavourite: false,

    seasonID: null,
    seasonDetails: {},

    leagueDetails: {},

    leagueID: null,

    matchID: null,
    selectedTimeUTC: '',

    homeTeamID: null,
    awayTeamID: null,
  },
  getters: {
    getSelectedMatches(state){
      return state.matches
    },
    getIsFavourite(state) {
      return state.isFavourite
    }
  },
  mutations: {
    setAllLeagueSchedule(state, payload){
      state.allLeagueSchedule = payload
    },
    setSelectedMatches(state, payload){
      state.matches = payload
    },
    setIsFavourite(state, payload){
      state.isFavourite = payload
    },
    setLeagueID(state, payload) {
      state.leagueID = payload
    },
    setSeasonDetails(state, payload) {
      state.seasonDetails = payload
    },
    setLeagueDetails(state, payload) {
      state.leagueDetails = payload
    },
    setSeasonID(state, payload) {
      state.seasonID = payload
    },
    setMatchID(state, payload) {
      state.matchID = payload
    },
    setSelectedTimeUTC(state, payload) {
      state.selectedTimeUTC = payload
    },
    setHomeTeamID(state, payload) {
      state.homeTeamID = payload
    },
    setAwayTeamID(state, payload) {
      state.awayTeamID = payload
    },
  },
  actions: {

  },

  modules: {
    Schedule,
    Standing,
    Teams,
    Draw,
    Search
  }
})
