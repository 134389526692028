<template>
  <div class="px-3">
    <div class="my-2">
      <v-chip 
        v-for="(nav, NI) in navs" 
        :key="NI" 
        @click="clickedTab(nav.id)" 
        class="mx-2 my-2 pointer font-weight-black f-12"
        :class="activeNavId === nav.id ? 'white background--text':'secondary special--text'"
      >
        {{nav.name}}
      </v-chip>
    </div>
    <H2HTable :H2H='H2H'/>
    <div v-if="activeNavId == 1">
      <H2HTable :H2H='H2H' :isLoading='isLoading' />
    </div>
    <div v-if="activeNavId == 2">
      <H2HTable :H2H='homeTeamMatches' :isLoading='isLoading' />
    </div>
    <div v-if="activeNavId == 3">
      <H2HTable :H2H='awayTeamMatches' :isLoading='isLoading' />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import H2HTable from '@/components/common/H2HTable'
export default {
  components: {
    H2HTable  
  },
  props: ['selectedMatch'],
  data() {
    return {
      navs: [
        {id: 1, name: 'H2H'},
        {id: 2, name: this.selectedMatch.teams.home.name},
        {id: 3, name: this.selectedMatch.teams.away.name},
      ],
      activeNavId: 1,

      allH2H: [],
      H2H: [],
      homeTeamMatches: [],
      awayTeamMatches: [],
      isLoading: false,
    }
  },
  methods: {
    ...mapActions(['Get_H2H_Tab_Content']),
    clickedTab(id) {
      this.activeNavId = id
    },
    async fetch(){
      this.isLoading = true
      await this.Get_H2H_Tab_Content(this.$store.state.matchID).then(data => {
        this.allH2H = data
        this.H2H = data.h2h
        this.homeTeamMatches = data.home.events.overall
        this.awayTeamMatches = data.away.events.overall
      }).catch(e=>{
        console.log(e);
      })
      this.isLoading = false
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang='sass'>
.pointer
  cursor: pointer
  text-transform: uppercase
.f-12
  font-size: 12px
</style>