var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.activeNavId)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"f-13 mb-0 special--text font-weight-bold"},[_vm._v("FIXTURES")]),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","icon":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-chevron-right")])],1)],1):_vm._e(),_c('div',{staticClass:"mt-3"},[(_vm.isLoading)?_c('div',{staticClass:"mx-3 mt-5 loading d-flex align-center justify-center"},[_c('div',[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)]):_vm._l((_vm.getSchedulesOf),function(schedule,sI){return _c('div',{key:sI,staticClass:"secondary px-2 py-2 match-single d-flex align-center mb-2 pointer"},[_c('div',{staticStyle:{"width":"40px"}},[(
            schedule.status == 1 ||
            schedule.status == 12 ||
            schedule.status == 13 ||
            schedule.status == 14 ||
            schedule.status == 15 ||
            schedule.status == 16
          )?_c('p',{staticClass:"mb-0 f-11 pr-1 text-center primary--text"},[_vm._v(" "+_vm._s(schedule.time.minute)+"' ")]):(schedule.status == 11)?_c('p',{staticClass:"mb-0 f-11 pr-1 text-center primary--text"},[_vm._v(" HT ")]):(schedule.status == 4)?_c('p',{staticClass:"mb-0 f-11 pr-1 text-center"},[_vm._v(" PSP ")]):(schedule.status == 3)?_c('p',{staticClass:"mb-0 f-11 pr-1 text-center"},[_vm._v(" FIN ")]):(schedule.status == 31)?_c('p',{staticClass:"mb-0 f-11 pr-1 text-center"},[_vm._v(" FIN P. ")]):(schedule.status == 5)?_c('p',{staticClass:"mb-0 f-11 pr-1 text-center"},[_vm._v(" CAN ")]):_c('p',{staticClass:"mb-0 f-11 pr-1 text-center"},[_c('span',[_vm._v(_vm._s(_vm.getDateMonth(schedule.time.date)))]),_vm._v(" "+_vm._s(schedule.time.time.split(":")[0] + ":" + schedule.time.time.split(":")[1])+" ")])]),_c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('img',{staticClass:"flag-image",attrs:{"src":schedule.teams.home.img,"height":"20px","width":"22px"}}),_c('span',{staticClass:"pl-3 f-14 font-weight-bold"},[_vm._v(_vm._s(schedule.teams.home.name))])]),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('img',{staticClass:"flag-image",attrs:{"src":schedule.teams.away.img,"height":"20px","width":"22px"}}),_c('span',{staticClass:"pl-3 f-14 font-weight-bold"},[_vm._v(_vm._s(schedule.teams.away.name))])])]),_c('v-spacer'),_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(schedule.scores.home_score))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(schedule.scores.away_score))])]),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":""}},[_c('v-icon',{staticClass:"star-icon"},[_vm._v("mdi-star-outline")])],1)],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }