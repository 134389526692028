import axios from '@/axios'

export default{
    state: {
    },
    getters: {
    
    },
    mutations: {
    },
    actions: {
      Get_League_Schedule({},data){
        return axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=schedule&d='+ data.date +'&lang='+ data.lang + '&utc='+ data.gmt)
        .get().then(user =>{
          return user.data.data   
        }).catch(error =>{
          console.log(error);
        })
      },
      Get_Single_League_Schedule({},payload){
        return axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=info&id='+ payload.id + '&utc='+ payload.gmt + '&include=odds_prematch')
        .get().then(user =>{
          return user.data.data
        }).catch(error =>{
          console.log(error);
        })
      },
      Get_Live_League_Schedule({}, lang){
        return axios.reqApi('livescores/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=live&lang='+ lang)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      async Get_Incidents_Tab_Content({}, id){
        return await axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_events&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Stats_Tab_Content({}, id){
        return axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_stats&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Comments_Tab_Content({}, id){
        return axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_comments&id=' + id + '&order=desc')
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      async Get_Lineups_Tab_Content({}, id){
        return await axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_lineups&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      async Get_Lineups_Bench_Tab_Content({}, id){
        return await axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_bench&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_H2H_Tab_Content({}, id){
        return axios.reqApi('h2h/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_TV_Tab_Content({}, id){
        return axios.reqApi('broadcasts/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_tvs&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Odds_Tab_Content({}, id){
        return axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_odds&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Fetch_Venue({}, id){
        return axios.reqApi('venues/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=info&id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Fetch_Odds_Prematch({}, id){
        return axios.reqApi('fixtures/?user=soccersapi2&token=3378a7f204490ff749196e91bacdbb51&t=match_odds_info&id='+ id +'&bookmaker_id=2')
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      }
    }
}