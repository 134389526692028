<template>
  <div>
    <h2>Head 2 Head</h2>
    <H2H :selectedMatch="selectedMatch" />
  </div>

</template>

<script>
import { mapActions } from "vuex"; 
import H2H from "@/components/match/H2H";

export default {
  components: { 
    H2H,
  },
    data() {
        return {
            selectedMatch: {},
        };
    },
    methods: {
        //...mapActions(["fetchMatch"]),
        ...mapActions(["Get_Single_League_Schedule"]),
    },
    created() {
        this.fetchMatch(this.$route.params.id);
        
    },

mounted() {
    try {
        this.selectedMatch = this.$route.params.id;
       // alert(this.$route.params.id);
    } catch (error) {
        console.error('Error in mounted hook:', error);
    }
}
};
</script>

 