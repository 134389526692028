<template>
  <div>
    <div class="px-3 py-3 border-bottom d-flex">
      <p class="f-14 special--text mb-0 font-weight-bold null-line-height" style="padding-bottom: 2px !important; padding-top: 2px !important">LIVE TABLE</p>
    </div>
    <div>
      <div>
        <div class="mx-6 my-4">
          <v-select
            v-model="selectedLeague"
            :items="allLeague"
            outlined
            dense
            hide-details=""
          ></v-select>
        </div>
        <div class="px-3 py-2">
          League Table
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allLeague: ['Premier League', 'Laliga', 'Champions League'],
      selectedLeague: 'Premier League',
    }
  }
}
</script>

<style scoped lang='sass'>
.null-line-height
  line-height: auto
.border-bottom
  border-bottom: 1px solid #222222
.f-14
  font-size: 14px
::v-deep .v-input
  background-color: #181818 !important
::v-deep .v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot
  border: 0px !important
</style>