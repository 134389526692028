<template>
  <div class="py-2">
    <div class="d-flex align-center px-3">
      <img
        :src="$store.state.seasonDetails.league.country_flag"
        height="18px"
        width="25px"
        class="flag-image ml-1"
      />
      <div class="pl-4 pointer">
        <p class="mb-0 font-weight-bold null-line-height">
          {{ $store.state.seasonDetails.league.name }}
        </p>
        <p class="mb-0 f-11">
          {{ $store.state.seasonDetails.league.country_name }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-star-outline</v-icon>
      </v-btn>
    </div>
    <div
      v-if="isLoading"
      class="mx-3 mt-5 game-score d-flex align-center justify-center"
    >
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="d-flex justify-space-around mx-3 game-score mt-5"
    >
      <div class="text-center" style="min-width: 30%">
        <img
          v-if="selectedMatch.teams.home.img"
          :src="selectedMatch.teams.home.img"
          height="30px"
          width="28px"
          class="flag-image ml-1"
        />
        <p
          v-if="selectedMatch.teams.home.name"
          class="mb-0 font-weight-bold f-12 mt-1"
        >
          {{ selectedMatch.teams.home.name }}
        </p>
      </div>
      <div class="text-center" style="min-width: 30%">
        <h2 v-if="selectedMatch.scores.ft_score" class="mb-1">
          {{ selectedMatch.scores.ft_score }}
        </h2>
        <h2 v-else class="mb-1">
          {{
            selectedMatch.time.time.split(":")[0] +
            ":" +
            selectedMatch.time.time.split(":")[1]
          }}
        </h2>
        <p
          v-if="
            selectedMatch.status == 1 ||
            selectedMatch.status == 12 ||
            selectedMatch.status == 13 ||
            selectedMatch.status == 14 ||
            selectedMatch.status == 15 ||
            selectedMatch.status == 16
          "
          class="mb-0 f-12 primary--text"
        >
          {{ selectedMatch.time.minute }}'
        </p>
        <p
          v-else-if="selectedMatch.status == 11"
          class="mb-0 f-12 primary--text"
        >
          HT
        </p>
        <p v-else-if="selectedMatch.status == 4" class="mb-0 f-12">PSP</p>
        <p v-else-if="selectedMatch.status == 3" class="mb-0 f-12">FIN</p>
        <p v-else-if="selectedMatch.status == 31" class="mb-0 f-12">FIN P.</p>
        <p v-else-if="selectedMatch.status == 5" class="mb-0 f-12">CAN</p>
        <p v-else class="mb-0 f-12">Today</p>
      </div>
      <div class="text-center" style="min-width: 30%">
        <img
          v-if="selectedMatch.teams.away.img"
          :src="selectedMatch.teams.away.img"
          height="30px"
          width="28px"
          class="flag-image ml-1"
        />
        <p
          v-if="selectedMatch.teams.away.name"
          class="mb-0 font-weight-bold f-12 mt-1"
        >
          {{ selectedMatch.teams.away.name }}
        </p>
      </div>
    </div>
    <v-card class="TabCard my-4" style="box-shadow: none">
      <v-tabs v-model="tab" background-color="#0B0B0B" dark class="px-3">
        <v-tab
          v-for="item in items"
          :key="item.tab"
          class="TabButton font-weight-bold"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="TabButtonActive">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat class="TabButtonActive">
            <div v-if="item.tab == 'Info'">
              <Info :selectedMatch="selectedMatch" :isLoading="isLoading" />
            </div>
            <div v-if="item.tab == 'Summary'">
              <Summary
                :halfTimeScore="halfTimeScore"
                :fullTimeScore="fullTimeScore"
              />
            </div>
            <div v-if="item.tab == 'Stats'">
              <Stats />
            </div>
            <div v-if="item.tab == 'Line-Ups'">
              <Lineups :selectedMatch="selectedMatch" />
            </div>
            <div v-if="item.tab == 'Table'">
              <Table />
            </div>
            <div v-if="item.tab == 'H2H'">
              <H2H :selectedMatch="selectedMatch" />
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Info from "@/components/match/Info";
import Summary from "@/components/match/Summary";
import Stats from "@/components/match/Stats";
import Lineups from "@/components/match/Lineups";
import Table from "@/components/league/Table";
import H2H from "@/components/match/H2H";

export default {
  components: {
    Info,
    Summary,
    Stats,
    Lineups,
    Table,
    H2H,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: "Info", id: 1 },
        { tab: "Summary", id: 2 },
        { tab: "Stats", id: 3 },
        { tab: "Line-Ups", id: 4 },
        { tab: "Table", id: 5 },
        { tab: "H2H", id: 6 },
      ],

      isLoading: false,
      halfTimeScore: "",
      fullTimeScore: "",
      selectedMatch: {},
      timeOut: null,
    };
  },
  methods: {
    ...mapActions(["Get_Single_League_Schedule"]),
    intervalFetchingSchedule() {
      this.timeOut = setInterval(() => {
        this.singleMatchData(false);
      }, 5000);
    },

    async singleMatchData(loading = true) {
      this.isLoading = loading;
      let payload = {
        id: this.$store.state.matchID,
        gmt: this.$store.state.selectedTimeUTC,
      };
      await this.Get_Single_League_Schedule(payload)
        .then((data) => {
          this.selectedMatch = data;

          console.log(this.selectedMatch, "this.selectedMatch");

          this.$store.commit("setHomeTeamID", data.teams.home.id);
          this.$store.commit("setAwayTeamID", data.teams.away.id);
          this.halfTimeScore = data.scores.ht_score;
          this.fullTimeScore = data.scores.ft_score;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timeOut);
  },
  created() {
    this.singleMatchData();
    this.intervalFetchingSchedule();
    console.log(this.$store.state.matchID, "matchID");
    console.log(this.$store.state.seasonID, "seasonID");
    console.log(this.$store.state.leagueID, "leagueID");
    console.log(this.$store.state.seasonDetails, "seasonDetails");
  },
};
</script>

<style scoped lang="sass">
.TabCard
  background-color: inherit !important
.TabButton
  height: 30px
  font-size: 12px
  padding: 0 !important
  &:hover
    background-color: inherit !important
.TabButtonActive
  background-color: inherit !important
  border-top: 1px solid #222222
::v-deep .v-tabs-slider-wrapper
  display: none !important
::v-deep .v-tabs
  height: 30px
  margin-bottom: 5px
.flag-image
  border-radius: 3px
.null-line-height
  line-height: auto
.f-11
  font-size: 11px
.game-score
  background-color: #181818
  padding: 12px 0
  border-radius: 7px
.f-12
  font-size: 12px
</style>
