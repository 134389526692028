<template>
  <v-container>
    <div>
      <router-view />
    </div>
  </v-container>
</template>

<script>
import AppHeader from '@/components/navigation/AppHeader'
import AppFooter from '@/components/navigation/AppFooter'
export default {
  components:{
    AppHeader,
    AppFooter
  }
};
</script>
