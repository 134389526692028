<template>
  <div>
    <div
      v-for="(schedule, sI) in getSchedulesOf"
      :key="sI"
      class="secondary px-2 py-2 match-single d-flex align-center mb-2 pointer"
    >
      <div style="width: 40px">
        <p class="mb-0 f-11 pr-1 text-center">
          <span>{{ getDateMonth(schedule.time.date) }}</span>
          FT
        </p>
      </div>
      <div>
        <div class="d-flex align-center mb-2">
          <img
            :src="schedule.teams.home.img"
            height="20px"
            width="22px"
            class="flag-image"
          />
          <span class="pl-3 f-14 font-weight-bold">{{
            schedule.teams.home.name
          }}</span>
        </div>
        <div class="d-flex align-center mt-2">
          <img
            :src="schedule.teams.away.img"
            height="20px"
            width="22px"
            class="flag-image"
          />
          <span class="pl-3 f-14 font-weight-bold">{{
            schedule.teams.away.name
          }}</span>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <p class="mb-0">{{ schedule.scores.home_score }}</p>
        <p class="mb-0">{{ schedule.scores.away_score }}</p>
      </div>
      <v-btn icon class="ml-3">
        <v-icon class="star-icon">mdi-star-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["fixtures"],
  computed: {
    getSchedulesOf() {
      return this.fixtures.filter((s) => s.status == 3);
    },
  },
  methods: {
    getDateMonth(value) {
      let date = new Date(value);
      return (
        date.toString().split(" ")[2].toUpperCase() +
        " " +
        date.toString().split(" ")[1]
      );
    },
  },
};
</script>

<style scoped lang="sass">
.match-single
  border-radius: 7px
  color: #AAAAAA
  &:hover
    background-color: #333333 !important
    span
      color: #FDFDFD
.f-11
  font-size: 11px
</style>
