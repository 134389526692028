<template>
  <div class="px-3 py-2">
    <p class="f-13 mb-0 special--text font-weight-bold">MATCH INFO</p>
    <div v-if="isLoading" class="mt-3 loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-else class="my-table special--text mt-3">
      <div class="d-flex table-row pa-3">
        <v-icon class="special--text" size="30">mdi-calendar</v-icon>
        <div class="pl-3">
          <p v-if="selectedMatch.time.date" class="mb-0">{{getFullDate(selectedMatch.time.date)}}</p>
          <p class="mb-0 f-13">Date</p>
        </div>
      </div>
      <div class="d-flex table-row pa-3">
        <v-icon class="special--text" size="30">mdi-account</v-icon>
        <div class="pl-3">
          <p class="mb-0">Demo Name</p>
          <p class="mb-0 f-13">Referee</p>
        </div>
      </div>
      <div class="d-flex table-row pa-3">
        <v-icon class="special--text" size="30">mdi-stadium</v-icon>
        <div class="pl-3">
          <p class="mb-0">Demo Name</p>
          <p class="mb-0 f-13">Venue</p>
        </div>
      </div>
      <div class="d-flex pa-3">
        <v-icon class="special--text" size="30">mdi-account-multiple</v-icon>
        <div class="pl-3">
          <span>0000</span>
          <p class="mb-0 f-13">Spectators</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selectedMatch', 'isLoading'],

  methods: {
    getFullDate(value) {
      let date= new Date(value)
      return date.toString().split(' ')[2].toUpperCase() + ' ' + this.getFullMonthName(date.toString().split(' ')[1].toUpperCase()) + ' ' + date.toString().split(' ')[3]
    },
    getFullMonthName(month) {
      let months = [
        {short: 'JAN', full: 'January'},
        {short: 'FEB', full: 'February'},
        {short: 'MAR', full: 'March'},
        {short: 'APR', full: 'April'},
        {short: 'MAY', full: 'May'},
        {short: 'JUN', full: 'June'},
        {short: 'JUL', full: 'July'},
        {short: 'AUG', full: 'August'},
        {short: 'SEP', full: 'September'},
        {short: 'OCT', full: 'October'},
        {short: 'NOV', full: 'November'},
        {short: 'DEC', full: 'December'},
      ]
      return months.filter(d=> d.short == month)[0].full
    },
  }
}
</script>

<style scoped lang='sass'>
.loading
  min-height: 300px
  width: 100%
  border: 1px solid #222222
  border-radius: 7px
.f-13
  font-size: 13px
.my-table
  border: 1px solid #222222
  border-radius: 7px
.table-row
  border-bottom: 1px solid #222222
</style>