<template>
  <div>
    <div v-if="isLoading" class="mx-3 mt-5 loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div 
      v-else
      v-for="(match, HI) in H2H" 
      :key="HI" 
      class="secondary px-2 py-2 match-single d-flex align-center mb-2 pointer" 
    >
      <div class="text-center" style="width: 50px">
        <span class="f-10">{{match.startdate.split('-')[0]}}</span>
        <p class="mb-0 f-13">FT</p>
      </div>
      <div>
        <div class="d-flex align-center mb-2">
          <img :src="match.teams.home.img" height="20px" width="22px" class="flag-image">
          <span class="pl-3 f-14 font-weight-bold">{{match.teams.home.name}}</span>
        </div>
        <div class="d-flex align-center mt-2">
          <img :src="match.teams.away.img" height="20px" width="22px" class="flag-image"> 
          <span class="pl-3 f-14 font-weight-bold">{{match.teams.away.name}}</span>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <p class="mb-0 mr-5">{{match.ft_score}}</p>
      </div>
      <div v-if="match.form">
        <div 
          class="f-13 d-flex justify-center align-center"
          :class="{'win-icon': match.form == 'W', 'loss-icon': match.form == 'L', 'draw-icon': match.form == 'D',}"
        >
          <p class="mb-0 font-weight-bold black--text">W</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    H2H: Array,
    isLoading: Boolean
  },
}
</script>

<style scoped lang='sass'>
.f-10
  font-size: 10px
.f-13
  font-size: 13px
.table-row
  border: 1px solid #222222
  border-top: 0px !important
.match-single
  border-radius: 7px
  color: #AAAAAA
  &:hover
    background-color: #333333 !important
    span
      color: #FDFDFD
.win-icon
  background: green
  height: 30px
  width: 30px
  border-radius: 50%
.loss-icon
  background: red
  height: 30px
  width: 30px
  border-radius: 50%
.draw-icon
  background: yellow
  height: 30px
  width: 30px
  border-radius: 50%
.loading
  min-height: 300px
  width: 100%
  border: 1px solid #222222
  border-radius: 7px
</style>