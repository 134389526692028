<template>
  <div class="px-3">
    <div class="my-2">
      <v-chip
        v-for="(nav, NI) in navs"
        :key="NI"
        @click="clickedTab(nav.id)"
        class="mx-2 my-2 pointer font-weight-black f-12"
        :class="
          activeNavId === nav.id
            ? 'white background--text'
            : 'secondary special--text'
        "
      >
        {{ nav.name }}
      </v-chip>
    </div>
    <div v-if="activeNavId == 1">
      <Fixture :activeNavId="activeNavId" :fixtures="fixtures" />
    </div>
    <div v-if="activeNavId == 2">
      <Results :fixtures="fixtures" />
    </div>
  </div>
</template>

<script>
import Fixture from "@/components/league/Overview";
import Results from "@/components/league/Results";
export default {
  components: {
    Fixture,
    Results,
  },
  data() {
    return {
      navs: [
        { id: 1, name: "FIXTURES" },
        { id: 2, name: "RESULTS" },
      ],
      activeNavId: 1,
    };
  },
  props: ["fixtures"],
  methods: {
    clickedTab(id) {
      this.activeNavId = id;
    },
  },
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.f-12
  font-size: 12px
</style>
