import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        background: '#FFFF',
        special: '#AAAAAA',
        white: '#FDFDFD',
        primary: '#EE883E',
        secondary: '#181818',
      },
      dark: {
        background: '#0B0B0B',
        special: '#AAAAAA',
        white: '#FDFDFD',
        primary: '#EE883E',
        secondary: '#181818',
      }
    }
  }
});
