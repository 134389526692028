import axios from '@/axios'

export default{
    state: {
    },
    getters: {
    
    },
    mutations: {
    },
    actions: {
      Get_Match_Details({},id){
        return axios.reqApi('teams/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=info&id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Last_Next_Matches({},id){
        return axios.reqApi('fixtures/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=last_next&team_id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Coach_Details({},id){
        return axios.reqApi('coaches/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=info&id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Match_Goal_Score({},data){
        return axios.reqApi('stats/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=team&id='+ data.TeamId +'&season_id='+ data.seasonId)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Team_Squad({},id){
        return axios.reqApi('teams/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=squad&id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Transfer_player({},id){
        return axios.reqApi('teams/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=transfers&id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Sidelined_player({},id){
        return axios.reqApi('teams/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=sidelined&id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Trophies({},id){
        return axios.reqApi('teams/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=trophies&id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Stats({},id){
        return axios.reqApi('stats/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=team&id='+ id.teamId +'&season_id=' + id.seasonId)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Fixtures({},id){
        return axios.reqApi('fixtures/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=season&season_id='+ id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
    }
}