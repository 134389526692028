import axios from '@/axios'

export default{
    state: {
      
    },
    getters: {
    
    },
    mutations: {
    },
    actions: {
      Get_Standing_Content({}, id){
        return axios.reqApi('leagues/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=standings&season_id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_LeagueInfo_Content({}, id){
        return axios.reqApi('leagues/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=info&id=777')
        .get().then(user =>{
          console.log(user.data.data );
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Topscorers_Content({}, id){
        return axios.reqApi('leaders/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=topscorers&season_id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Topassists_Content({}, id){
        return axios.reqApi('leaders/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=topassists&season_id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
      Get_Topcards_Content({}, id){
        return axios.reqApi('leaders/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=topcards&season_id=' + id)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },

    }
}