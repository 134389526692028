import axios from '@/axios'

export default{
    state: {
    },
    getters: {
    
    },
    mutations: {
    },
    actions: {
        Get_Draw_Content({},id){
        return axios.reqApi('leagues/?user=obulto&token=48087d58527a45cf9ec0ecfb3131f227&t=cup_draw&season_id=' +  id.seasonId + '&stage_id=' + id.stageId)
        .get().then(user =>{
          return user.data.data 
        }).catch(error =>{
            console.log(error);
        })
      },
    }
}