<template>
  <div>
    <types />
    <div class="d-flex" style="width: 100%">
      <v-col class="px-2 col-md-2 left-col">
        <div class="col-style">
          <search
            :allLeagueSchedule="allLeagueSchedule"
            :isLoading="isLoading"
          />
        </div>
      </v-col>
      <v-col class="px-2 col-md-6 col-sm-12 col-xs-12 middle-col">
        <div class="col-style">
          <router-view :key="$route.fullPath" />
          <score
            @date="newDate"
            @showLive="showLive"
            @destroyInterval="destroyInterval"
            :isLoading="isLoading"
            :allLeagueSchedule="allLeagueSchedule"
            v-if="$route.name == 'Game'"
          />
        </div>
      </v-col>
      <v-col class="px-2 col-md-4 right-col">
        <div class="col-style mb-4">
          <news />
        </div>
        <div class="col-style">
          <live-table />
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Types from "@/components/common/Types";
import Score from "@/components/score/Score";
import Search from "@/components/search/SearchV2";
import News from "@/components/news/News";
import LiveTable from "@/components/live/LiveTable";
export default {
  name: "Home",

  components: {
    Types,
    Score,
    Search,
    News,
    LiveTable,
  },
  data() {
    return {
      selectedDate: "",
      isLiveChecked: false,
      isLoading: false,
      selectedLang: "en",
      allLeagueSchedule: [],
      timeOut: null,
    };
  },
  watch: {
    getIsFavourite(v) {
      if (v) {
        let id = this.getSelectedMatches[this.selectedDate];
        if (id) {
          this.allLeagueSchedule = this.allLeagueSchedule.filter((s) =>
            id.includes(s.id)
          );
        }
      } else {
        this.fetchLeague();
      }
    },
  },
  computed: {
    ...mapGetters(["getSelectedMatches", "getIsFavourite"]),
  },
  methods: {
    ...mapActions(["Get_League_Schedule", "Get_Live_League_Schedule"]),
    ...mapMutations(["setIsFavourite"]),
    showLive(value) {
      this.isLiveChecked = value;
      if (value) {
        this.allLeagueSchedule = [];
        this.fetchLive();
      } else {
        this.fetchLeague();
      }
    },
    newDate(value) {
      this.selectedDate = value;
      this.isLiveChecked = false;
      this.setIsFavourite(false);
      clearInterval(this.timeOut);
      this.intervalFetchingSchedule();
      if (this.$route.path == "/en/football/favourites")
        this.$router.push("/en/football");
    },
    async fetchLive(loading = true) {
      this.isLoading = loading;
      await this.Get_Live_League_Schedule(this.selectedLang)
        .then((data) => {
          if (data) {
            this.allLeagueSchedule = data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
    async fetchLeague(loading = true) {
      this.isLoading = loading;
      let toGetScheduleData = {
        date: this.selectedDate,
        lang: this.selectedLang,
        gmt: this.$store.state.selectedTimeUTC,
      };
      await this.Get_League_Schedule(toGetScheduleData)
        .then((data) => {
          this.allLeagueSchedule = data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
    intervalFetchingSchedule() {
      this.timeOut = setInterval(() => {
        if (this.getIsFavourite) {
          let id = this.getSelectedMatches[this.selectedDate];
          if (id) {
            this.allLeagueSchedule = this.allLeagueSchedule.filter((s) =>
              id.includes(s.id)
            );
          }
        } else if (!this.isLiveChecked) {
          this.fetchLeague(false);
        } else {
          this.fetchLive(false);
        }
      }, 5000);
    },
    destroyInterval() {
      clearInterval(this.timeOut);
    },
  },
  created() {
    this.isLoading = true;
    this.intervalFetchingSchedule();
  },
};
</script>

<style lang="sass" scoped>
.col-style
  border: 1px solid #222222
  border-radius: 10px
.left-col
  @media screen and (max-width: 961px)
    display: none
.middle-col
  @media screen and (max-width: 961px)
    width: 100% !important
.right-col
  @media screen and (max-width: 961px)
    display: none
</style>
