<template>
  <v-container>
    <app-header />
    <div>
      <router-view />
    </div>
    <app-footer />
  </v-container>
</template>

<script>
import AppHeader from '@/components/navigation/AppHeader'
import AppFooter from '@/components/navigation/AppFooter'
export default {
  components:{
    AppHeader,
    AppFooter
  }
};
</script>
