<template>
  <div>
    <div v-if="isLoading" class="loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <!-- Don't have any group -->
      <div v-if="allTableData.has_groups == 0">
        <StandingAwayTable :standingData="standingData" />
      </div>
      <!-- Have groups -->
      <div v-else>
        <!-- Group A -->
        <div v-if="group_a">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group A</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_a" />
        </div>
        <!-- Group B -->
        <div v-if="group_b">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group B</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_b" />
        </div>
        <!-- Group C -->
        <div v-if="group_c">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group C</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_c" />
        </div>
        <!-- Group D -->
        <div v-if="group_d">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group D</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_d" />
        </div>
        <!-- Group E -->
        <div v-if="group_e">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group E</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_e" />
        </div>
        <!-- Group F -->
        <div v-if="group_f">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group F</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_f" />
        </div>
        <!-- Group G -->
        <div v-if="group_g">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group G</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_g" />
        </div>
        <!-- Group H -->
        <div v-if="group_h">
          <p class="mb-0 pt-1 pb-1 pl-3 font-weight-bold">Group H</p>
        </div>
        <div>
          <StandingAwayTable :standingData="group_h" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import StandingAwayTable from "@/components/common/StandingAwayTable.vue";
export default {
  components: {
    StandingAwayTable,
  },
  data() {
    return {
      isLoading: false,
      allTableData: [],
      standingData: [],
      group_a: [],
      group_b: [],
      group_c: [],
      group_d: [],
      group_e: [],
      group_f: [],
      group_g: [],
      group_h: [],
    };
  },
  watch: {
    $route(to, from) {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions(["Get_Standing_Content"]),
    fetchData() {
      this.isLoading = true;
      this.Get_Standing_Content(this.$store.state.seasonID)
        .then((data) => {
          this.allTableData = data;
          if (data.has_groups == 0) {
            let SortForstandingData = data.standings;
            this.standingData = SortForstandingData.sort(
              (a, b) =>
                parseFloat(a.away.position) - parseFloat(b.away.position)
            );
          } else {
            this.group_a = data.standings.group_a;
            this.group_b = data.standings.group_b;
            this.group_c = data.standings.group_c;
            this.group_d = data.standings.group_d;
            this.group_e = data.standings.group_e;
            this.group_f = data.standings.group_f;
            this.group_g = data.standings.group_g;
            this.group_h = data.standings.group_h;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="sass">
.loading
  min-height: 300px
  width: 100%
</style>
