<template>
  <div class="my-table f-13">
    <div v-if="isLoading" class="loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div
      v-else
      class="my-row border-b d-flex"
      v-for="(comment, CI) in allComments"
      :key="CI"
    >
      <v-col cols="2">
        <p v-if="!comment.extra_minute" class="mb-0">{{ comment.minute }}'</p>
        <p v-if="comment.extra_minute" class="mb-0">
          {{ comment.minute }}+{{ comment.extra_minute }}'
        </p>
      </v-col>
      <v-col cols="10">
        {{ comment.comment }}
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      allComments: [],
      timeOut: null,
    };
  },
  methods: {
    ...mapActions(["Get_Comments_Tab_Content"]),
    intervalFetchingSchedule() {
      this.timeOut = setInterval(() => {
        this.commentContent(false);
      }, 5000);
    },
    async commentContent(loading = true) {
      this.isLoading = loading;
      await this.Get_Comments_Tab_Content(this.$store.state.matchID)
        .then((data) => {
          this.allComments = data;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timeOut);
  },
  created() {
    this.commentContent();
    this.intervalFetchingSchedule();
  },
};
</script>

<style scoped lang="sass">
.loading
  min-height: 300px
  width: 100%
.my-table
  border: 1px solid #222222
  border-radius: 7px
.border-b
  border-bottom: 1px solid #222222
.f-13
  font-size: 13px
</style>
