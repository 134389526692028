<template>
  <div class="my-table f-13">
    <div v-if="isLoading" class="loading d-flex align-center justify-center">
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <div
        class="my-row border-b d-flex align-center mx-0"
        v-for="(Incident, lI) in allIncidents"
        :key="lI"
      >
        <v-col cols="2">
          <p v-if="Incident.extra_minute == null" class="mb-0 f-13">
            {{ Incident.minute }}'
          </p>
          <p v-if="Incident.extra_minute" class="mb-0 f-13">
            {{ Incident.minute }} + {{ Incident.extra_minute }}'
          </p>
        </v-col>
        <v-col cols="4">
          <div
            v-if="Incident.team_id == $store.state.homeTeamID"
            class="d-flex align-center justify-end"
          >
            <div>
              <p class="mr-4 mb-0">{{ nameFormation(Incident.player_name) }}</p>
              <p
                v-if="Incident.related_player_name != null"
                class="mr-4 mb-0 special--text"
              >
                {{ nameFormation(Incident.related_player_name) }}
              </p>
            </div>
            <div v-if="Incident.type == 'goal'" class="cardIcon-football"></div>
            <div v-if="Incident.type == 'injury'" class="cardIcon-injury"></div>
            <div
              v-if="Incident.type == 'back from injury'"
              class="cardIcon-back-from-injury"
            ></div>
            <div
              v-if="Incident.type == 'substitution'"
              class="cardIcon-exchange"
            ></div>
            <div
              v-if="Incident.type == 'yellowcard'"
              class="cardIcon-yellow"
            ></div>
            <div v-if="Incident.type == 'redcard'" class="cardIcon-red"></div>
          </div>
        </v-col>
        <v-col cols="2">
          {{ Incident.result }}
        </v-col>
        <v-col cols="4">
          <div
            v-if="Incident.team_id == $store.state.awayTeamID"
            class="d-flex align-center"
          >
            <div v-if="Incident.type == 'goal'" class="cardIcon-football"></div>
            <div v-if="Incident.type == 'injury'" class="cardIcon-injury"></div>
            <div
              v-if="Incident.type == 'back from injury'"
              class="cardIcon-back-from-injury"
            ></div>
            <div
              v-if="Incident.type == 'substitution'"
              class="cardIcon-exchange"
            ></div>
            <div
              v-if="Incident.type == 'yellowcard'"
              class="cardIcon-yellow"
            ></div>
            <div v-if="Incident.type == 'redcard'" class="cardIcon-red"></div>
            <div>
              <p class="ml-4 mb-0">{{ nameFormation(Incident.player_name) }}</p>
              <p
                v-if="Incident.related_player_name != null"
                class="ml-4 mb-0 special--text"
              >
                {{ nameFormation(Incident.related_player_name) }}
              </p>
            </div>
          </div>
        </v-col>
      </div>
      <div class="d-flex">
        <v-col cols="2"> FT </v-col>
        <v-col cols="4"> </v-col>
        <v-col cols="2">
          {{ fullTimeScore }}
        </v-col>
        <v-col cols="4"> </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      allIncidents: [],
      isLoading: false,
      timeOut: null,
    };
  },
  props: ["halfTimeScore", "fullTimeScore"],
  methods: {
    ...mapActions(["Get_Incidents_Tab_Content"]),
    intervalFetchingSchedule() {
      this.timeOut = setInterval(() => {
        this.getIncidentData(false);
      }, 5000);
    },
    async getIncidentData(loading = true) {
      this.isLoading = loading;
      await this.Get_Incidents_Tab_Content(this.$store.state.matchID)
        .then((data) => {
          this.allIncidents = data;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    nameFormation(v) {
      if (v) {
        let displayName;
        let arrayOfName = v.split(", ");
        let firstName;
        if (arrayOfName[1]) {
          let splitName = arrayOfName[1].split("");
          firstName = splitName[0];
        }
        if (firstName) {
          displayName = firstName + ". " + arrayOfName[0];
        } else {
          displayName = arrayOfName[0];
        }
        return displayName;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timeOut);
  },
  created() {
    this.getIncidentData();
    this.intervalFetchingSchedule();
  },
};
</script>

<style scoped lang="sass">
.loading
  min-height: 300px
  width: 100%
.my-table
  border: 1px solid #222222
  border-radius: 7px
.border-b
  border-bottom: 1px solid #222222
.f-13
  font-size: 13px
.cardIcon-football
  background-image: url('../../assets/icons/Football.png')
  background-size: cover
  height: 16px
  width: 16px
.cardIcon-exchange
  background-image: url('../../assets/icons/exchange.png')
  background-size: cover
  height: 16px
  width: 16px
.cardIcon-injury
  background-image: url('../../assets/icons/injury.png')
  background-size: cover
  height: 16px
  width: 16px
.cardIcon-back-from-injury
  background-image: url('../../assets/icons/back-from-injury.png')
  background-size: cover
  height: 16px
  width: 16px
.cardIcon-yellow
  background: yellow
  height: 18px
  width: 14px
  border-radius: 2px
.cardIcon-red
  border-radius: 2px
  background: red
  height: 18px
  width: 14px
</style>
