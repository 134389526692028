<template>
  <div>
    <div
      class="d-flex justify-space-between align-center special--text px-3 py-2 border-bottom"
    >
      <div>
        <v-btn
          v-if="!showOnlyDate"
          @click="liveClicked"
          :class="showLiveGames ? 'primary' : 'white'"
          small
          ><span :class="showLiveGames ? 'white--text' : 'black--text'"
            >live</span
          ></v-btn
        >
        <div v-else @click="selectedDate = today" class="pointer">
          <h5 class="text-center">{{ getDayName(today) }}</h5>
          <p class="mb-0 f-11 text-center">{{ getDateMonth(today) }}</p>
        </div>
      </div>
      <div
        v-if="!showOnlyDate"
        class="d-flex justify-space-around"
        style="width: 100%"
      >
        <div
          v-for="(date, DI) in allDates"
          :key="DI"
          class="pointer"
          :class="selectedDate == date ? 'primary--text' : ''"
          @click="selectedDate = date"
        >
          <h5 class="text-center">{{ getDayName(date) }}</h5>
          <p class="mb-0 f-11 text-center">{{ getDateMonth(date) }}</p>
        </div>
      </div>
      <div v-if="showOnlyDate" class="primary--text">
        <h5 class="text-center">{{ getDayName(selectedDate) }}</h5>
        <h6 class="mb-0 f-11 text-center">{{ getFullDate(selectedDate) }}</h6>
      </div>
      <div>
        <v-btn icon @click="showDatePicker = true">
          <v-icon color="white">mdi-calendar-today</v-icon>
        </v-btn>
      </div>
    </div>
    <v-row v-if="showDatePicker" justify="center" class="date-picker">
      <v-date-picker v-model="selectedDate" :landscape="true" color="primary">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="showDatePicker = false">
          Cancel
        </v-btn>
      </v-date-picker>
    </v-row>
    <div class="px-3 py-2 table-area">
      <div
        v-if="isLoading"
        class="table-area d-flex align-center justify-center"
      >
        <div>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
      <div v-else>
        <div v-for="(leagueId, lI) in filteredLeagueSchedule" :key="lI">
          <div class="d-flex align-center">
            <img
              :src="getSchedulesOf(leagueId)[0].league.country_flag"
              height="18px"
              width="25px"
              class="flag-image ml-1"
            />
            <div
              class="pl-4 pointer"
              @click="showLeague(getSchedulesOf(leagueId)[0])"
            >
              <p class="mb-0 font-weight-bold null-line-height">
                {{ getSchedulesOf(leagueId)[0].league.name }}
              </p>
              <p class="mb-0 f-11">
                {{ getSchedulesOf(leagueId)[0].league.country_name }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="showLeague(getSchedulesOf(leagueId)[0])">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="mt-1 mb-3">
            <div
              v-for="(schedule, sI) in getSchedulesOf(leagueId)"
              :key="sI"
              class="secondary px-2 py-2 match-single d-flex align-center mb-2 pointer"
              @click="matchDetails(schedule, getSchedulesOf(leagueId)[0])"
            >
              <div style="width: 40px">
                <p
                  v-if="
                    schedule.status == 1 ||
                    schedule.status == 12 ||
                    schedule.status == 13 ||
                    schedule.status == 14 ||
                    schedule.status == 15 ||
                    schedule.status == 16
                  "
                  class="mb-0 f-11 pr-1 text-center primary--text"
                >
                  {{ schedule.time.minute }}'
                </p>
                <p
                  v-else-if="schedule.status == 11"
                  class="mb-0 f-11 pr-1 text-center primary--text"
                >
                  HT
                </p>
                <p
                  v-else-if="schedule.status == 4"
                  class="mb-0 f-11 pr-1 text-center"
                >
                  PSP
                </p>
                <p
                  v-else-if="schedule.status == 3"
                  class="mb-0 f-11 pr-1 text-center"
                >
                  FIN
                </p>
                <p
                  v-else-if="schedule.status == 31"
                  class="mb-0 f-11 pr-1 text-center"
                >
                  FIN P.
                </p>
                <p
                  v-else-if="schedule.status == 5"
                  class="mb-0 f-11 pr-1 text-center"
                >
                  CAN
                </p>
                <p v-else class="mb-0 f-11 pr-1 text-center">
                  {{
                    schedule.time.time.split(":")[0] +
                    ":" +
                    schedule.time.time.split(":")[1]
                  }}
                </p>
              </div>
              <div>
                <div class="d-flex align-center mb-2">
                  <img
                    :src="schedule.teams.home.img"
                    height="20px"
                    width="22px"
                    class="flag-image"
                  />
                  <span class="pl-3 f-14 font-weight-bold">{{
                    schedule.teams.home.name
                  }}</span>
                </div>
                <div class="d-flex align-center mt-2">
                  <img
                    :src="schedule.teams.away.img"
                    height="20px"
                    width="22px"
                    class="flag-image"
                  />
                  <span class="pl-3 f-14 font-weight-bold">{{
                    schedule.teams.away.name
                  }}</span>
                </div>
              </div>
              <v-spacer></v-spacer>
              <div>
                <p class="mb-0">{{ schedule.scores.home_score }}</p>
                <p class="mb-0">{{ schedule.scores.away_score }}</p>
              </div>
              <v-btn icon class="ml-3" @click="markMyGame(schedule)">
                <v-icon v-if="!markedGame(schedule.id)" class="star-icon"
                  >mdi-star-outline</v-icon
                >
                <v-icon v-else color="primary">mdi-star</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      showDatePicker: false,
      selectedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      selectedGamesObj: {},
      allDates: [],
      today: "",
      showOnlyDate: false,
      showLiveGames: false,
    };
  },
  props: {
    isLoading: Boolean,
    allLeagueSchedule: Array,
  },
  watch: {
    selectedDate() {
      this.showDatePicker = false;
      this.showLiveGames = false;
      this.$emit("date", this.selectedDate);
      this.$emit("showLive", this.showLiveGames);
      if (!this.allDates.includes(this.selectedDate)) this.showOnlyDate = true;
      else this.showOnlyDate = false;
    },
  },
  computed: {
    ...mapGetters(["getSelectedMatches"]),
    filteredLeagueSchedule() {
      return this.allLeagueSchedule
        .map((s) => s.league)
        .map((l) => l.id)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
  },
  methods: {
    ...mapMutations(["setSelectedMatches"]),
    matchDetails(match, seasonDetails) {
      let country = seasonDetails.league.country_name.toLowerCase();
      let league = seasonDetails.league.name.replace(/ /g, "-").toLowerCase();
      let teams =
        match.teams.home.name.replace(/ /g, "-").toLowerCase() +
        "-vs-" +
        match.teams.away.name.replace(/ /g, "-").toLowerCase();
      let id = match.id;
      this.$store.commit("setMatchID", match.id);
      this.$store.commit("setSeasonDetails", seasonDetails);
      this.$store.commit("setSeasonID", seasonDetails.season_id);
      this.$router.push(
        "/en/football/" + country + "/" + league + "/" + teams + "/" + id
      );
    },
    showLeague(payload) {
      let country = payload.league.country_name.toLowerCase();
      let id = payload.season_id;
      let league = payload.league.name.replace(/ /g, "-").toLowerCase();
      this.$store.commit("setSeasonDetails", payload);
      this.$store.commit("setLeagueID", payload.league.id);
      this.$store.commit("setSeasonID", payload.season_id);
      this.$router.push("/en/football/" + country + "/" + league);
    },
    liveClicked() {
      this.showLiveGames = !this.showLiveGames;
      this.$emit("showLive", this.showLiveGames);
    },
    getFullDayName(day) {
      let Days = [
        { short: "MON", full: "MONDAY" },
        { short: "TUE", full: "TUESDAY" },
        { short: "WED", full: "WEDNESDAY" },
        { short: "THU", full: "THURSDAY" },
        { short: "FRI", full: "FRIDAY" },
        { short: "SAT", full: "SATURDAY" },
        { short: "SUN", full: "SUNDAY" },
      ];
      return Days.filter((d) => d.short == day)[0].full;
    },
    getFullMonthName(month) {
      let months = [
        { short: "JAN", full: "JANUARY" },
        { short: "FEB", full: "FEBRUARY" },
        { short: "MAR", full: "MARCH" },
        { short: "APR", full: "APRIL" },
        { short: "MAY", full: "MAY" },
        { short: "JUN", full: "JUNE" },
        { short: "JUL", full: "JULY" },
        { short: "AUG", full: "AUGUST" },
        { short: "SEP", full: "SEPTEMBER" },
        { short: "OCT", full: "OCTOBER" },
        { short: "NOV", full: "NOVEMBER" },
        { short: "DEC", full: "DECEMBER" },
      ];
      return months.filter((d) => d.short == month)[0].full;
    },
    getDayName(value) {
      let date = new Date(value);
      if (value == this.today) return "TODAY";
      if (this.showOnlyDate)
        return this.getFullDayName(date.toString().split(" ")[0].toUpperCase());
      return date.toString().split(" ")[0].toUpperCase();
    },
    getDateMonth(value) {
      let date = new Date(value);
      return (
        date.toString().split(" ")[2].toUpperCase() +
        " " +
        date.toString().split(" ")[1]
      );
    },
    getFullDate(value) {
      let date = new Date(value);
      return (
        date.toString().split(" ")[2].toUpperCase() +
        " " +
        this.getFullMonthName(date.toString().split(" ")[1].toUpperCase()) +
        " " +
        date.toString().split(" ")[3]
      );
    },
    getSchedulesOf(leagueId) {
      return this.allLeagueSchedule.filter((s) => s.league.id == leagueId);
    },
    markedGame(id) {
      let matchId = this.getSelectedMatches[this.selectedDate];
      if (matchId) {
        if (matchId.includes(id)) {
          return true;
        }
      }
    },
    markMyGame(value) {
      this.selectedGamesObj = this.getSelectedMatches;
      let array = [];
      if (this.selectedGamesObj[this.selectedDate]) {
        array = this.selectedGamesObj[this.selectedDate];
      }
      if (!array.includes(value.id)) {
        array.push(value.id);
      } else {
        array = array.filter((item) => item != value.id);
      }
      this.selectedGamesObj[this.selectedDate] = array;
      this.setSelectedMatches(this.selectedGamesObj);
    },
    dateSetter() {
      for (let i = -2; i < 3; i++) {
        var date = new Date();
        var res = date.setTime(date.getTime() + i * 24 * 60 * 60 * 1000);
        var d = new Date(res);
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var output =
          d.getFullYear() +
          "-" +
          (month < 10 ? "0" : "") +
          month +
          "-" +
          (day < 10 ? "0" : "") +
          day;
        if (i == 0) this.today = output;
        this.allDates.push(output);
      }
    },
  },
  beforeDestroy() {
    this.$emit("destroyInterval");
    this.$store.commit("setAllLeagueSchedule", this.allLeagueSchedule);
  },
  created() {
    this.dateSetter();
    this.$emit("date", this.selectedDate);
  },
};
</script>

<style scoped lang="sass">
.border-bottom
  border-bottom: 1px solid #222222
.f-11
  font-size: 11px
.f-14
  font-size: 14px
.date-picker
  position: absolute
  left: 50%
  transform: translate( -50%)
  z-index: 99
.null-line-height
  line-height: auto
.match-single
  border-radius: 7px
  color: #AAAAAA
  &:hover
    background-color: #333333 !important
    span
      color: #FDFDFD
.flag-image
  border-radius: 3px
.table-area
  min-height: 300px
.star-icon
  &:hover
    color: #EE883E
</style>
