<template>
  <div>
    <div class="px-3 py-3 border-bottom d-flex">
      <p class="mb-0 font-weight-bold null-line-height" style="padding-bottom: 2px !important; padding-top: 2px !important">Featured News</p>
      <v-spacer></v-spacer>
      <v-icon>mdi-chevron-right</v-icon>
    </div>
    <div class="px-3 py-2">
      News Here ...
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang='sass'>
.null-line-height
  line-height: auto
.border-bottom
  border-bottom: 1px solid #222222
</style>