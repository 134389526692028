<template>
  <div class="setting-sec mt-3">
    <div class="py-2 text-center">
      <p class="mb-0 font-weight-bold">Settings</p>
    </div>
    <div class="border-top py-2 d-flex justify-center">
      <div class="setting col-md-6 col-sm-12 col-xs-12 px-0 pb-1 mb-3">
        <div>
          <label for="timezone" class="font-weight-bold">Timezone</label>
          <v-select
            v-model="selectedTimeUTC"
            :items="TimeZonesUTC"
            outlined
            dense
            hide-details=""
            class="mt-3 mb-2"
          ></v-select>
          <span class="f-15 special--text font-weight-bold"
            >Select your timezone preference</span
          >
        </div>
        <div class="border-top pt-2 mt-2">
          <p class="mb-2 font-weight-bold">Select Theme</p>
          <ThemeConfig />
        </div>
        <div>
          <v-btn class="primary apply-btn">Apply</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeConfig from "@/components/common/ThemeConfig";
export default {
  data() {
    return {
      selectedTimeUTC: 6,
      TimeZonesUTC: [
        { text: "GMT -11:30", value: -11.5 },
        { text: "GMT -11:00", value: -11 },
        { text: "GMT -10:30", value: -10.5 },
        { text: "GMT -10:00", value: -10 },
        { text: "GMT -09:30", value: -9.5 },
        { text: "GMT -09:00", value: -9 },
        { text: "GMT -08:30", value: -8.5 },
        { text: "GMT -08:00", value: -8 },
        { text: "GMT -07:30", value: -7.5 },
        { text: "GMT -07:00", value: -7 },
        { text: "GMT -06:30", value: -6.5 },
        { text: "GMT -06:00", value: -6 },
        { text: "GMT -05:30", value: -5.5 },
        { text: "GMT -05:00", value: -5 },
        { text: "GMT -04:30", value: -4.5 },
        { text: "GMT -04:00", value: -4 },
        { text: "GMT -03:30", value: -3.5 },
        { text: "GMT -03:00", value: -3 },
        { text: "GMT -02:30", value: -2.5 },
        { text: "GMT -02:00", value: -2 },
        { text: "GMT -01:30", value: -1.5 },
        { text: "GMT -01:00", value: -1 },
        { text: "GMT +00:00", value: 0 },
        { text: "GMT +01:00", value: 1 },
        { text: "GMT +01:30", value: 1.5 },
        { text: "GMT +02:00", value: 2 },
        { text: "GMT +02:30", value: 2.5 },
        { text: "GMT +03:00", value: 3 },
        { text: "GMT +03:30", value: 3.5 },
        { text: "GMT +04:00", value: 4 },
        { text: "GMT +04:30", value: 4.5 },
        { text: "GMT +05:00", value: 5 },
        { text: "GMT +05:30", value: 5.5 },
        { text: "GMT +06:00", value: 6 },
        { text: "GMT +06:30", value: 6.5 },
        { text: "GMT +07:00", value: 7 },
        { text: "GMT +07:30", value: 7.5 },
        { text: "GMT +08:00", value: 8 },
        { text: "GMT +08:30", value: 8.5 },
        { text: "GMT +09:00", value: 9 },
        { text: "GMT +09:30", value: 9.5 },
        { text: "GMT +10:00", value: 10 },
        { text: "GMT +10:30", value: 10.5 },
        { text: "GMT +11:00", value: 11 },
        { text: "GMT +11:30", value: 11.5 },
      ],
    };
  },
  components: {
    ThemeConfig,
  },
  watch: {
    selectedTimeUTC() {
      this.$store.commit("setSelectedTimeUTC", this.selectedTimeUTC);
    },
  },
  methods: {
    timeZone() {
      if (this.$store.state.selectedTimeUTC) {
        this.selectedTimeUTC = this.$store.state.selectedTimeUTC;
      } else {
        this.selectedTimeUTC = new Date().getTimezoneOffset() / -60;
        this.$store.commit("setSelectedTimeUTC", this.selectedTimeUTC);
      }
    },
  },
  created() {
    this.timeZone();
  },
};
</script>

<style scoped lang="sass">
.setting-sec
  border: 1px solid #222222
  border-radius: 10px
.border-top
  border-top: 1px solid #222222
.setting
  @media screen and (max-width: 961px)
    width: 100% !important
::v-deep .v-input
  background-color: #181818 !important
  font-size: 14px !important
::v-deep .v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot
  border: 0px !important
.f-15
  font-size: 15px
.apply-btn
  width: 100% !important
  margin-top: 30px
</style>
